<template>
  <div id="aqt_image">
    <div class="aqt_image_list">
      <div class="history aqt_su">
        <img class="info_intro" :src="sidebar_pic" alt=""/>
        <div class="contentfont aqt_lang_mn" style="color: #003461; margin-right: 0.7rem">
          {{ searchStr }}
        </div>
        <lay-list :dataList="dataList" :isLoading="isLoading" @on-line-click="onLineClick"></lay-list>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    "$store.state.refresh": function (newVal) {
      console.log("refresh");
      if (newVal == 1) {
        this.$store.state.refresh = 0;
        this.condition.page = 1;
        this.$store.state.search.title = ""; //书名
        this.$store.state.search.name = ""; //书名
        this.$store.state.search.category_id = ""; //书籍分类id
        this.$store.state.search.category_name = ""; //书籍分类名称
        this.$store.state.search.culture_id = ""; //文化分类id
        this.$store.state.search.culture_name = ""; //文化分类名称
        this.$store.state.search.press_id = ""; //出版社id
        this.$store.state.search.press_name = ""; //出版社名称
        this.$store.state.search.isbn = ""; //书号
        this.$store.state.search.author = ""; //作者名称
        this.$store.state.search.start_time = ""; //出版开始时间
        this.$store.state.search.end_time = ""; //出版结束时间
        this.dataList = [];
        this.queryData();
      }
    },
    "$store.state.search": {
      // 表示对象中属性变化的处理函数，这个函数只能叫这个名字
      handler(newVal) {
        console.log(newVal, "书");
        this.condition.name = newVal.name; //书名
        this.condition.category_id = newVal.category_id; //书籍分类id
        this.condition.culture_id = newVal.culture_id; //文化分类id
        this.condition.press_id = newVal.press_id; //出版社id
        this.condition.isbn = newVal.isbn; //书号
        this.condition.author = newVal.author; //作者名称
        this.condition.start_time = newVal.start_time; //出版开始时间
        this.condition.end_time = newVal.end_time; //出版结束时间
        this.condition.page = 1; //页数
        this.dataList = [];
        this.queryData();
      },
      immediate: false,
      deep: true, // 表示开启深度监听
    },
  },
  data() {
    return {
      ScrollLeft: 0,
      searchStr: "",
      isLoads: false,
      dataList: [],
      isMounted: false,
      condition: {
        // member_id: JSON.parse(localStorage.getItem('userInfoMemberId')).member_id,
        name: "", //书名
        category_id: "", //书籍分类id
        culture_id: "", //文化分类id
        press_id: "", //出版社id
        library_id: this.$route.query.number ? this.$route.query.number : (JSON.parse(localStorage.getItem("theme_color")).library_id ? JSON.parse(localStorage.getItem("theme_color")).library_id : ''), //馆藏id  48内蒙古财经大学图书馆
        isbn: "", //书号
        author: "", //作者名称
        start_time: "", //出版开始时间
        end_time: "", //出版结束时间
        page: 1,
      },
      dataListUrl: "api/web/v1/h5/booklist",
      isLoading: false,
      sidebar_pic:'',
    };
  },
  methods: {
    /*****************************************************************
     *
     * 获取列表数据
     *
     *****************************************************************/
    queryData() {
      const _this = this;

      _this.showToastMask(true);
      _this.isLoads = false;
      _this.isLoading = false
      _this.condition.lang = 'mn'
      _this.$http.get(`${_this.dataListUrl}`, {params: _this.condition,})
          .then((val) => {
            let oldpage = _this.condition.page;
            let last_page = val.pages.last_page;
            _this.condition.page = val.pages.current_page;
            if (_this.condition.page == last_page) {
              // 表示已是最后一页
              _this.condition.page = last_page;
              _this.isLoads = false;
            } else {
              _this.condition.page = Number(_this.condition.page) + 1;
              _this.isLoads = true;
            }
            if (val.data.length) {
              if (oldpage > val.pages.current_page) {
                return;
              }
              let ff = _this.processData(val.data);
              ff.forEach((it) => {
                _this.dataList.push(it);
              });
            } else {
              _this.dataList = _this.processData(val.data);
            }
            _this.isLoading = true
            _this.showToastMask(false);
          }).catch((err) => {
        _this.showToastMask(false);
        _this.isLoads = false;
        _this.isLoading = true
      });
    },
    getsidebar_pic() {
      const _this = this;
      const id = this.$route.query.number ? this.$route.query.number : (JSON.parse(localStorage.getItem("theme_color")).library_id ? JSON.parse(localStorage.getItem("theme_color")).library_id : '')
      _this.$http.get('api/web/v1/h5/search?library_id=' + id)
          .then((val) => {
            console.log(val, 88)
            this.sidebar_pic=val.data.sidebar_pic
          }).catch((err) => {
      })
    },

    /*****************************************************************
     *
     * 数据处理
     *
     *****************************************************************/
    processData(data) {
      let ss = [];
      data.forEach((item, index) => {
        ss.push({
          content_id: item.user_count_id,
          id: item.book_id,
          img: item.img,
          publish_date: item.publish_date,
          annexcount: item.annexcount,
          title: (item.name_lang.mn ? item.name_lang.mn : item.name_lang.zh),
          author: (item.author_lang.mn ? item.author_lang.mn : item.author_lang.zh),
          press: (item.press_name_lang.mn ? item.press_name_lang.mn : item.press_name_lang.zh),
          tclassname: (item.name_lang.mn ? "aqt_lang_mn" : item.name_lang.zh ? "aqt_su" : "aqt_lang_mn"),
          aclassname: (item.author_lang.mn ? "aqt_lang_mn" : item.author_lang.zh ? "aqt_su" : "aqt_lang_mn"),
          pclassname: (item.press_name_lang.mn ? "aqt_lang_mn" : item.press_name_lang.zh ? "aqt_su" : "aqt_lang_mn"),
        });
      });
      return ss;
    },

    /*****************************************************************
     *
     * 点击搜索
     *
     *****************************************************************/
    onSearchClick(item) {
      let _this = this;
      _this.condition.name = item.name; //书名
      _this.condition.category_id = item.category_id; //书籍分类id
      _this.condition.culture_id = item.culture_id; //文化分类id
      _this.condition.press_id = item.press_id; //出版社id
      _this.condition.isbn = item.isbn; //书号
      _this.condition.author = item.author; //作者名称
      _this.condition.start_time = item.start_time; //出版开始时间
      _this.condition.end_time = item.end_time; //出版结束时间
      _this.condition.page = 1;
      _this.searchStr = item.name + "  " + item.category_name + "  " + item.culture_name + "  " + item.press_name + "  " + item.isbn + "  " + item.author + "  " + item.start_time + "  " + item.end_time;
      _this.dataList = [];
      _this.queryData();
    },

    /*****************************************************************
     *
     * 详情
     *
     *****************************************************************/
    onLineClick(item) {
      let _this = this;
      _this.$router.push({
        path: "BookInfo",
        query: {
          id: item.id,
        },
      });
    },
  },
  mounted() {
    console.log(99, document.getElementsByClassName("history"))
    const _this = this;
    InfiniteScroll(
        document.getElementsByClassName("history")[0],

        function () {
          if (_this.isLoads) {
            console.log(444444);
            _this.queryData();
            return;
          }
        },
        this.loadDistance
    );
    this.getsidebar_pic()
  },
  computed: {
    library_id: {
      get: function () {
        let myDate = JSON.parse(localStorage.getItem("theme_color")).library_id;
        console.log(myDate, 'myDate')
        return myDate;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    // 语言
    lang () {
      return this.$store.getters.lang
    },
    // sidebar_pic() {
    //   let vv = JSON.parse(localStorage.getItem("theme_color")) ? JSON.parse(localStorage.getItem("theme_color")).sidebar_pic : null;
    //   return vv;
    // },
  },
  created() {
    const _this = this;
    _this.isMounted = true;

    _this.queryData();


  },

};
</script>

<style lang="less">
#aqt_image {
  width: 100%;
  height: calc(100% - 5rem);
  position: relative;

  .aqt_image_list {
    position: absolute;
    top: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem;
    right: 0.5rem;
    overflow-x: auto;
    overflow-y: hidden;

    .history {
      width: 100%;
      overflow-x: scroll;
      height: calc(100vh - 6rem);

      .info_intro {
        height: 100%;
      }
    }
  }
}
</style>
